<template>
    <div class='h-full'>
        <NavBar />
        <div v-if='invitation && !isInvitationExpired'>
            <WizardTransitions>
                <BreadCrumbs
                    :steps='wizardSteps'
                    :history='history'
                    v-model='step'
                    key='breadcrumbs'
                />
            </WizardTransitions>
            <WizardTransitions>
                <WizardScreen key='confirm' v-if='step === "confirm"' class='flex-col justify-start'>
                    <TermsConditionsPopUp :show='showTermsPopup' :close='closeTermsPopup' />
                    <WizardBox animated class='mt-12'>
                        <Translated>
                            <template #en>
                                <div class='mb-12 font-light text-center text-6xl'>
                                    <span class='font-black'>{{ invitation.invitedByUser.firstName }} {{ invitation.invitedByUser.lastName }}</span> invited you to access <br><span class='font-black'>{{ invitationEntityName }}</span> via MyCodabox<br>
                                </div>
                                <div class='font-light text-center text-xl'>
                                    <span v-if='invitation.organization'>This will allow you to manage companies and your organization directly in MyCodabox.<br></span>
                                    Not expecting this invitation? Then just close this browser window.
                                </div>
                            </template>
                            <template #nl>
                                <div class='mb-12 font-light text-center text-6xl'>
                                    <span class='font-black'>{{ invitation.invitedByUser.firstName }} {{ invitation.invitedByUser.lastName }}</span> nodigde je uit om MyCodabox te gebruiken voor <span class='font-black'>{{ invitationEntityName }}</span>
                                </div>
                                <div class='font-light text-center text-xl'>
                                    <span v-if='invitation.organization'>Dit zal je toelaten om dossiers en jouw organisatie rechtstreeks te beheren in MyCodabox.<br></span>
                                    Verwacht je deze uitnodiging niet? Sluit dan gewoon dit browservenster.
                                </div>
                            </template>
                            <template #fr>
                                <div class='mb-12 font-light text-center text-6xl'>
                                    <span class='font-black'>{{ invitation.invitedByUser.firstName }} {{ invitation.invitedByUser.lastName }}</span> vous a invité à rejoindre <span class='font-black'>{{ invitationEntityName }}</span>
                                </div>
                                <div class='font-light text-center text-xl'>
                                    <span v-if='invitation.organization'>Cela vous permettra de gérer les dossiers et votre organisation directement dans MyCodabox.<br></span>
                                    Vous ne vous attendiez pas à cette invitation ? Fermez simplement cette fenêtre de navigateur.
                                </div>
                            </template>
                        </Translated>
                        <ButtonBar thinner>
                            <FriendlyButton
                                label='btn-invitation-accept'
                                symbol='arrow-right'
                                square
                                :action='confirm'
                            />
                        </ButtonBar>
                        <div class='mt-4'>
                            <span class='text-gray-400'>
                                {{ $t('terms-and-conditions-disclaimer-existing') }} <a class='cursor-pointer' @click='openTermsPopup'>{{ $t('a-terms-and-conditions') }}</a>.
                            </span>
                        </div>
                    </WizardBox>
                </WizardScreen>

                <WizardScreen key='done' v-if='step === "done"' class='flex-col justify-start'>
                    <WizardBox animated class='mt-12'>
                        <Translated>
                            <template #en>
                                <div class='mb-12 font-light text-center text-6xl'>
                                    <span class='font-black'>Thanks!</span><br>You have accepted the invitation for <span class='font-black'>{{ invitationEntityName }}</span>
                                </div>
                                <div class='font-light text-center text-xl'>
                                    You’re just one click away from exploring MyCodabox.
                                </div>
                            </template>
                            <template #nl>
                                <div class='mb-12 font-light text-center text-6xl'>
                                    <span class='font-black'>Bedankt!</span><br>Je hebt de uitnodiging voor <span class='font-black'>{{ invitationEntityName }}</span> geaccepteerd
                                </div>
                                <div class='font-light text-center text-xl'>
                                    Je bent nog maar één klik verwijderd van het verkennen van MyCodabox.
                                </div>
                            </template>
                            <template #fr>
                                <div class='mb-12 font-light text-center text-6xl'>
                                    <span class='font-black'>Merci !</span><br>Vous avez accepté l'invitation pour rejoindre <span class='font-black'>{{ invitationEntityName }}</span>
                                </div>
                                <div class='font-light text-center text-xl'>
                                    Vous n'êtes plus qu'à un clic d'explorer MyCodabox.
                                </div>
                            </template>
                        </Translated>
                        <ButtonBar thinner>
                            <div
                                @mouseover='isHovering = true'
                                @mouseout='isHovering = false'
                            >
                                <FriendlyButton
                                    label='btn-invitation-back-home'
                                    symbol='home'
                                    square
                                    :action='() => { $router.push(invitation.organization ? `/organization/${invitation.organization.id}/` : `/reseller/${invitation.reseller.id}/`) }'
                                />
                            </div>
                        </ButtonBar>
                    </WizardBox>
                </WizardScreen>
            </WizardTransitions>
        </div>
        <div v-else-if='!loading' class='flex flex-col h-full justify-center pb-24'>
            <WizardTransitions>
                <WizardScreen>
                    <WizardBox>
                        <div class='mt-12 font-light text-center text-6xl'>
                            <span class='font-black'>
                                <template v-if='isInvitationExpired'>
                                    {{ $t('invitation-wizard-expired-invitation-title') }}
                                </template>
                                <template v-else>
                                    {{ $t('invitation-wizard-invalid-invitation-title') }}
                                </template>
                            </span>
                        </div>
                        <div class='mt-12 text-center text-2xl'>
                            <template v-if='isInvitationExpired'>
                                {{ $t('invitation-wizard-expired-invitation-text') }}
                            </template>
                            <template v-else>
                                {{ $t('invitation-wizard-invalid-invitation-text') }}
                            </template>
                        </div>
                        <ButtonBar thinner v-if='!isInvitationExpired'>
                            <div
                                @mouseover='isHovering = true'
                                @mouseout='isHovering = false'
                            >
                                <FriendlyButton
                                    label='btn-invitation-back-home'
                                    symbol='home'
                                    square
                                    :action='() => $router.push("/")'
                                />
                            </div>
                        </ButtonBar>
                    </WizardBox>
                </WizardScreen>
            </WizardTransitions>
        </div>
    </div>
</template>

<script>
import { authFetch as fetch } from '../api/fetch.js';
import utils from '../utils.js';
import NavBar from '@/components/NavBar';
import BreadCrumbs from '../clientcomponents/BreadCrumbs.vue';
import WizardTransitions from '../clientcomponents/WizardTransitions.vue';
import WizardScreen from '../clientcomponents/WizardScreen.vue';
import WizardBox from '../clientcomponents/WizardBox.vue';
import ButtonBar from '../clientcomponents/ButtonBar.vue';
import FriendlyButton from '../clientcomponents/FriendlyButton.vue';
import notify from '@/notify';
import loader from '@/loader.js';
import TermsConditionsPopUp from '@/user/TermsConditionsPopUp.vue';
import Translated from '@/components/Translated';

export default {
    name: 'ConfirmInvitation',
    components: {
        TermsConditionsPopUp,
        NavBar,
        BreadCrumbs,
        WizardTransitions,
        WizardScreen,
        WizardBox,
        ButtonBar,
        FriendlyButton,
        Translated,
    },
    data () {
        return {
            loading: false,
            confirming: false,
            step: 'confirm',
            history: ['confirm'],
            invitation: null,
            showTermsPopup: false,
            isInvitationExpired: false,
        };
    },
    computed: {
        wizardSteps () {
            return [
                {
                    keys: ['confirm'],
                    label: 'lbl-user-confirm-invitation',
                },
                {
                    keys: ['done'],
                    label: 'lbl-user-invitation-confirmed',
                    freeze: ['confrim'],
                },
            ];
        },
        invitationEntityName () {
            return this.invitation.organization ?  this.invitation.organization.enterpriseName : this.invitation.reseller.name;
        },
    },
    async created () {
        const loadId = loader.start();
        this.loading = true;

        const invitationResponse = await this.getInvitation();

        // Only set local variables when request is valid and invitation is found
        // If the variables are not set, it will display the "invalid" information text
        if (invitationResponse.ok) {
            this.invitation = await invitationResponse.json();
            this.isInvitationExpired = Date.now() > new Date(this.invitation.expiresAt);
        }

        loader.stop(loadId);
        this.loading = false;
    },
    methods: {
        async confirm () {
            this.confirming = true;

            const response = await this.completeInvitation();

            if (response.ok) {
                this.nextStep('done');
            } else if (response.status === 400) {
                const errorCode = (await response.json()).errorCode;

                if (errorCode === 'invitationExpired') {
                    this.isInvitationExpired = true;
                } else if (errorCode === 'emailsAreDifferent') {
                    notify.error(this.$t('err-confirm-invite-different-users'));
                } else {
                    notify.error(this.$t('err-unknown'));
                }
            } else {
                notify.error(this.$t('err-unknown'));
            }

            this.confirming = false;
        },
        nextStep (step) {
            this.step = step;
            setTimeout(() => {
                utils.scrollTop();
            }, 150);
        },
        getInvitation () {
            return fetch('/bff/api/invitation?token=' + this.$route.params.token, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },
        completeInvitation () {
            return fetch('/bff/api/invitation/complete?token=' + this.$route.params.token, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },
        openTermsPopup (e) {
            e.stopPropagation();
            this.showTermsPopup = true;
        },
        closeTermsPopup () {
            this.showTermsPopup = false;
        },
    },
};
</script>
