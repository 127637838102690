<script setup>
    import download from 'downloadjs';
    import notify from '../notify.js';
    import { authFetch as fetch } from '../api/fetch.js';
    import Tooltip from '@/components/Tooltip';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import { t } from '@/i18n';
    import Loader from '@/loader';

    const props = defineProps({
        mandate: {
            type: Object,
            required: true,
        },
        labelLink: {
            type: String,
            default: 'lbl-start-digital-signing',
        },
        newFlow: {
            type: Boolean,
            default: false,
        },
        showInPopup: {
            type: Boolean,
            default: false,
        },
    });

    const emits = defineEmits(['showPopup']);

    async function doDownload () {
        Loader.start();
        try {
            const res = await fetch(
                        `bff/api/downloads/coda-mandate?mandateId=${props.mandate.id}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/pdf',
                    },
                },
                    );
            if (res.status !== 200) {
                throw Error(res.statusText);
            }
            download(await res.blob(), 'coda_mandate.pdf');
        } catch {
            notify.error(t('err-unknown'));
        } finally {
            Loader.stop();
        }
    }
</script>
<template>
    <div v-if='showInPopup'>
        <FriendlyButton
            label='btn-download'
            :action='doDownload'
            extra-small
            square
            symbol='download'
            no-margin
        />
    </div>
    <div class='flex' v-else-if='!newFlow'>
        <!-- If a bank has disabled pdf mandate, it has to have Twikey or Nitro active.
    This could be updated in the future if there is a signhereSupported field -->
        <Tooltip v-if='mandate.bank.disableSendPdfMandate' left>
            <template #trigger>
                <FriendlyButton
                    label='btn-download-complete'
                    square
                    extra-small
                    micro
                    no-margin
                    :disabled='mandate.bank.disableSendPdfMandate'
                />
            </template>
            <template #content>
                <div v-if='mandate.bank.isTwikeySupported'>
                    {{ $t('p-pdf-no-download-twikey') }}
                </div>
                <div v-else-if='!mandate.bank.isTwikeySupported'>
                    {{ $t('p-pdf-no-download-digitally-signed') }}
                </div>
            </template>
        </Tooltip>
        <div v-else>
            <FriendlyButton
                label='btn-download-complete'
                square
                :action='doDownload'
                extra-small
                micro
                no-margin
            />
        </div>
    </div>
    <div v-else class='sign-button'>
        <FriendlyButton
            :label='labelLink'
            :action='() => emits("showPopup")'
            extra-small
            square
            micro
            no-margin
        />
        <FriendlyButton
            :action='doDownload'
            square
            micro
            no-margin
            symbol='download'
            class='ml-2'
            no-text
            secondary
            no-background
            fat-icon
        />
    </div>
</template>

<style lang='postcss' scoped>

</style>
