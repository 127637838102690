<script setup>
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import Popup from '@/clientcomponents/Popup.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();

const emits = defineEmits(['hasClickedDone']);
const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    close: {
        type: Function,
        required: true,
    },
    isDirectCustomer: {
        type: Boolean,
        required: true,
    },
});

const ready = () => {
    emits('hasClickedDone');
    props.close();
};

// use JSON.stringify to build a composite key
const faqLinks = new Map([
    [JSON.stringify([true, 'nl_BE']), 'https://faq.codabox.com/nl/support/solutions/articles/75000055221'],
    [JSON.stringify([true, 'en_US']), 'https://faq.codabox.com/en/support/solutions/articles/75000055221'],
    [JSON.stringify([true, 'fr_FR']), 'https://faq.codabox.com/fr/support/solutions/articles/75000055221'],
    [JSON.stringify([false, 'nl_BE']), 'https://faq.codabox.com/nl/support/solutions/articles/75000126343'],
    [JSON.stringify([false, 'en_US']), 'https://faq.codabox.com/en/support/solutions/articles/75000126343'],
    [JSON.stringify([false, 'fr_FR']), 'https://faq.codabox.com/fr/support/solutions/articles/75000126343'],
]);

const faqLink = computed(() => {
    return faqLinks.get(JSON.stringify([props.isDirectCustomer, locale.value])) || 'https://faq.codabox.com';
});

</script>

<template>
    <Popup
        :show='props.show'
        :close='props.close'
    >
        <template #header>
            <h1 class='text-4xl'>
                {{ $t("lbl-congrats") }}
            </h1>
        </template>

        <template #default>
            <div class='flex flex-row items-center'>
                <img class='w-32 mx-4' src='@/assets/party.png' alt='congrats'>
                <div class='max-w-66'>
                    <i18n-t keypath='p-signature-popup-done.text' tag='p'>
                        <a :href='faqLink' target='_blank'>{{ $t('p-signature-popup-done.link-description') }}</a>
                    </i18n-t>
                </div>
            </div>
        </template>

        <template #buttons>
            <FriendlyButton
                :label='$t("lbl-step-done")'
                type='submit'
                square
                extra-small
                no-margin
                symbol='check'
                :action='ready'
            />
        </template>
    </Popup>
</template>
