<script>
    import { gql } from '@apollo/client/core';
    import CodaCard from '../components/CodaCard';
    import ResendCodaButton from '@/fiduciary/components/ResendCodaButton';
    import Tooltip from '@/components/Tooltip.vue';
    import Loader from '@/loader';
    import validate from '@/validate';
    import ContentBox from '@/components/ContentBox';
    import SendSodaCodaByMail from './components/SendSodaCodaByMail';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import InfoBanner from '@/components/InfoBanner';
    import InfoBannerItem from '@/components/InfoBannerItem';
    import OrderCoda from '@/fiduciary/components/OrderCoda.vue';
    import notify from '@/notify';
    import { t } from '@/i18n.js';
    import SignCoda from '@/fiduciary/components/SignCoda.vue';

    export default {
        name: 'ClientCoda',
        props: {
            clientV2: { type: Object, required: true },
            currentEnvironment: { type: Object, required: true },
            accountant: { type: Object, required: true },
        },
        components: {
            SignCoda,
            OrderCoda,
            CodaCard,
            ResendCodaButton,
            Tooltip,
            ContentBox,
            SendSodaCodaByMail,
            FriendlyButton,
            InfoBanner,
            InfoBannerItem,
        },
        data () {
            return {
                highlightedCodaMandateId: null,
                saving: false,
                codaMandates: null,
                newBankAccounts: [],
                codaLoaded: false,
                showOrderCodaMandatePopup: false,
                newlyOrderedMandates: [],
                showSigningOrchestrator: false,
            };
        },
        computed: {
            bankAccounts () {
                if (!this.codaMandates) {
                    return [];
                }
                let bankAccounts = [];
                for (const mandate of this.codaMandates) {
                    const bankName = mandate.bank.name;
                    bankAccounts = bankAccounts.concat(
                        mandate.bankAccounts.map(
                            ba => ({ bankName, ...ba }),
                        ),
                    );
                }
                return bankAccounts;
            },
            invalidRepresentativeName () {
                return !validate.isComposedName(this.clientV2.representativeName);
            },
            missingContactEmail () {
                return !this.clientV2.contactEmail;
            },
        },
        async beforeMount () {
            await this.getCodaMandates();
            await this.getCodaRedeliveryRequests(this.clientV2.id, this.codaMandates.map(mandate => { return mandate.id; }));
        },
        async mounted () {
            this.highlightCodaMandate();
            this.showNewCodaMandatePopup();
        },
        emits: ['clientupdated'],
        methods: {
            fetchAndRemoveQueryParam (param) {
                const { [param]: paramValue } = this.$route.query;
                if (!paramValue) return;

                // clean query param from the url (avoid highlight at refresh or if page is bookmarked)
                this.$router.replace({ query: { ...this.$route.query, [param]: undefined } });
                return paramValue;
            },
            showNewCodaMandatePopup () {
                const showPopup = this.fetchAndRemoveQueryParam('showNewCodaMandatePopup');
                if (showPopup && this.canRequestNewCodaMandate()) {
                    this.openOrderCodaMandatePopup();
                }
            },
            highlightCodaMandate () {
                const codaMandateId = this.fetchAndRemoveQueryParam('highlightedCodaMandateId');
                if (codaMandateId) {
                    this.highlightedCodaMandateId = codaMandateId;
                }
            },
            canRequestNewCodaMandate () {
                return !(this.missingContactEmail || this.invalidRepresentativeName);
            },
            async getCodaMandates () {
                Loader.start();
                const { data } = await this.$apollo.query({
                    query: gql`query getCodaMandate($clientId: String) {
                            codaMandates(clientId:$clientId, excludeMandatesWithoutBankAccounts:true) {
                                id
                                clientId
                                fiduciaryId
                                bankId
                                bank {
                                    id
                                    name
                                    slug
                                    abbreviation
                                    isSupported
                                    isTwikeySupported
                                    disableSendPdfMandate
                                    isCaroSupported
                                    isStopCodaSupported
                                    regexSavings
                                    isSignhereSupported
                                }
                                state
                                reasonCode
                                reasonNote
                                stateDate
                                bankAccounts{
                                    id
                                    type
                                    bankId
                                    state
                                    stateDate
                                    flowState
                                    bankMandateState
                                    iban
                                }
                                signHerePackage {
                                    packageStatus
                                    packageSignUrl
                                    packageRejectionReason
                                }
                                twikeyUrl
                                twikeyUrlCreatedAt
                                hasTeletransmissionPdf
                                reminders {
                                    date
                                    targetEmail
                                    type
                                }
                            }
                        }`,
                    variables: {
                        clientId: this.clientV2.id,
                    },
                });
                data.codaMandates.forEach(mandate => {
                    mandate.hasRedeliveryRequest = false;
                });
                this.codaMandates = data.codaMandates;
                this.codaLoaded = true;
                Loader.stop();
            },
            openOrderCodaMandatePopup () {
                this.showOrderCodaMandatePopup = true;
            },
            async closeOrderCodaMandatePopup (createdMandates, iCanSignNow) {
                this.newlyOrderedMandates = createdMandates;
                if (createdMandates) {
                    if (!iCanSignNow) {
                        await this.getCodaMandates();
                        notify.success(t('p-order-coda-success'));
                    } else {
                        this.showSigningOrchestrator = true;
                    }
                }
                this.showOrderCodaMandatePopup = false;
            },
            async getCodaRedeliveryRequests (clientId, codaMandates) {
                if (codaMandates.length > 0) {
                    const { data } = await this.$apollo.query({
                        query: gql`query codaRedeliverySearch($clientId: String, $mandates: [String]!, $state: [String]) {
                            codaRedeliverySearch(clientId: $clientId, codaMandates: $mandates, states: $state) {
                                mandate
                                bankAccount
                                state
                            }
                        }`,
                        variables: {
                            clientId,
                            mandates: codaMandates,
                            state: ['pending'],
                        },
                    });
                    data.codaRedeliverySearch.forEach(crr => {
                        const mandate = this.codaMandates.find(mandate => {
                            return mandate.id === crr.mandate;
                        });
                        if (mandate) {
                            mandate.hasRedeliveryRequest = true;
                        }
                    });
                }
            },
        },
    };
</script>
<template>
    <div class='client-subppage'>
        <InfoBanner class='mb-6'>
            <InfoBannerItem v-if='!clientV2.contactEmail' id='coda-missing-contact-email-warning'>
                {{ $t('info-coda-missing-contact-email') }}
            </InfoBannerItem>
            <InfoBannerItem v-if='invalidRepresentativeName' id='coda-invalid-representative-name-warning'>
                {{ $t('info-coda-invalid-representative-name') }}
            </InfoBannerItem>
        </InfoBanner>
        <div v-if='codaLoaded'>
            <content-box class='mb-6' :title='$t("ttl-mandates")'>
                <template #actions>
                    <div class='flex'>
                        <ResendCodaButton
                            class='mr-3'
                            :client-v2='clientV2'
                            :bank-accounts='bankAccounts'
                        />

                        <FriendlyButton
                            label='btn-new-coda-mandate'
                            :action='openOrderCodaMandatePopup'
                            :disabled='!canRequestNewCodaMandate()'
                            square
                            extra-small
                            no-margin
                            id='orderCodaMandateButton'
                            symbol='plus'
                            data-dd-action-name='btn-order-coda'
                        />
                    </div>
                </template>
                <div v-if='codaMandates' class='grid grid-cols-2 gap-6'>
                    <div>
                        <div v-for='(mandate, index) in codaMandates' class='grid grid-cols-1 gap-6'>
                            <CodaCard
                                :accountant='accountant'
                                :client-v2='clientV2'
                                :mandate='mandate'
                                :key='index'
                                :selected='(highlightedCodaMandateId === mandate.id) && codaMandates.length > 1'
                                :animated='!!highlightedCodaMandateId'
                                @get-coda-mandates-event='getCodaMandates'
                                :id='"codaCard_"+mandate.id'
                                v-if='index % 2 === 0'
                            />
                        </div>
                    </div>
                    <div>
                        <div v-for='(mandate, index) in codaMandates' class='grid grid-cols-1 gap-6'>
                            <CodaCard
                                :accountant='accountant'
                                :client-v2='clientV2'
                                :mandate='mandate'
                                :key='index'
                                :selected='(highlightedCodaMandateId === mandate.id) && codaMandates.length > 1'
                                :animated='!!highlightedCodaMandateId'
                                @get-coda-mandates-event='getCodaMandates'
                                :id='"codaCard_"+mandate.id'
                                v-if='index % 2 !== 0'
                            />
                        </div>
                    </div>
                </div>
                <tooltip big v-if='codaMandates && codaMandates.length === 0'>
                    <template #trigger>
                        <span
                            class='py-1 px-3 rounded-full bg-green-300 inline-block bg-grey-300 bg-opacity-20'
                        >
                            {{ $t('state-no-coda') }}
                        </span>
                    </template>
                    <template #content>
                        {{ $t('p-no-coda') }}
                    </template>
                </tooltip>
            </content-box>
        </div>
        <SendSodaCodaByMail
            v-if='codaMandates && codaMandates.length > 0'
            :client-v2='clientV2'
            :accountant='accountant'
            @clientupdated='$emit("clientupdated")'
        />
        <OrderCoda
            :show-order-coda-mandate-popup='showOrderCodaMandatePopup'
            :client-v2='clientV2'
            :current-environment='currentEnvironment'
            :is-direct-customer='accountant.isDirectCustomer'
            @close='(changed, iCanSign) => closeOrderCodaMandatePopup(changed, iCanSign)'
        />
        <SignCoda
            :client-v2='clientV2'
            :mandates='newlyOrderedMandates'
            :show='showSigningOrchestrator'
            :is-direct-customer='accountant.isDirectCustomer'
            @close='() => {showSigningOrchestrator = false}'
        />
    </div>
</template>

<style lang='postcss' scoped>
    .client-subppage {
        min-height: calc(100vh - 450px);
    }

    .client-subppage-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0 20px;

        h1, h2, h3, h4, h5, h6 {
            margin: 0;
        }
    }

    .client-subppage-header .btn+.btn {
        @apply ml-3;
    }

    .client-page__settings {
        margin-top: 20px;
    }

    .client-subppage-subtitle {
        margin: 40px 0 20px 0;
    }

    .client-subppage-header__actions {
        @apply flex items-center ml-auto;
    }
</style>
