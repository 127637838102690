<template>
    <div class='h-full'>
        <NavBar />
        <div v-if='invitation && !isInvitationExpired'>
            <WizardTransitions>
                <BreadCrumbs
                    :steps='wizardSteps'
                    :history='history'
                    v-model='step'
                    key='breadcrumbs'
                    value=''
                />
            </WizardTransitions>
            <WizardTransitions>
                <WizardScreen key='information' v-if='step === "information"' class='flex-col justify-start'>
                    <WizardBox animated class='mt-12'>
                        <Translated>
                            <template #en>
                                <div class='mb-12 font-light text-center text-6xl'>
                                    Ready to use MyCodabox for <br><span class='font-black'>{{ invitationEntityName }}</span>?
                                </div>
                                <div class='font-light text-center text-xl'>
                                    Let’s start with your first and last name.
                                </div>
                            </template>
                            <template #nl>
                                <div class='mb-12 font-light text-center text-6xl'>
                                    Klaar om MyCodabox te gebruiken voor <br><span class='font-black'>{{ invitationEntityName }}</span>?
                                </div>
                                <div class='font-light text-center text-xl'>
                                    Laten we beginnen met je voor- en achternaam.
                                </div>
                            </template>
                            <template #fr>
                                <div class='mb-12 font-light text-center text-6xl'>
                                    Prêt à utiliser MyCodabox pour <br><span class='font-black'>{{ invitationEntityName }}</span> ?
                                </div>
                                <div class='font-light text-center text-xl'>
                                    Commençons par vos nom et prénom.
                                </div>
                            </template>
                        </Translated>
                        <Form
                            tag='div'
                            ref='userInfomationsForm'
                            class='flex flex-col items-center py-6'
                            @submit='goToPassword'
                            v-slot='{ values, errors }'
                        >
                            <FormInput
                                autocomplete='given-name'
                                :label='$t("lbl-firstname")'
                                name='firstname'
                                rules='required'
                                ref='firstnameInput'
                                mode='aggressive'
                                edit
                            />
                            <FormInput
                                autocomplete='family-name'
                                :label='$t("lbl-lastname")'
                                name='lastname'
                                rules='required'
                                ref='lastnameInput'
                                mode='aggressive'
                                edit
                            />
                            <ButtonBar thinner no-margin>
                                <FriendlyButton
                                    label='btn-next'
                                    symbol='arrow-right'
                                    square
                                    type='submit'
                                    :disabled='!values.firstname || !values.lastname || errors.length > 0'
                                />
                            </ButtonBar>
                        </Form>
                    </WizardBox>
                </WizardScreen>

                <WizardScreen key='password' v-if='step === "password"' class='flex-col justify-start'>
                    <TermsConditionsPopUp :show='showTermsPopup' :close='closeTermsPopup' />
                    <WizardBox animated class='mt-12'>
                        <Translated>
                            <template #en>
                                <div class='mb-12 font-light text-center text-6xl'>
                                    Hey <span class='font-black'>{{ user.firstname }}</span>,<br>
                                    it’s time to set your password
                                </div>
                                <div class='font-light text-center text-xl'>
                                    This is what you will use to log in to MyCodabox.
                                </div>
                            </template>
                            <template #nl>
                                <div class='mb-12 font-light text-center text-6xl'>
                                    Hey <span class='font-black'>{{ user.firstname }}</span>,<br>
                                    het is tijd om je wachtwoord in te stellen
                                </div>
                                <div class='font-light text-center text-xl'>
                                    Dit is wat je gaat gebruiken om in te loggen op MyCodabox.
                                </div>
                            </template>
                            <template #fr>
                                <div class='mb-12 font-light text-center text-6xl'>
                                    Hey <span class='font-black'>{{ user.firstname }}</span>,<br>
                                    il est temps de définir votre mot de passe
                                </div>
                                <div class='font-light text-center text-xl'>
                                    C'est ce que vous utiliserez pour vous connecter à MyCodabox.
                                </div>
                            </template>
                        </Translated>
                        <Form
                            tag='div'
                            ref='passwordForm'
                            class='flex flex-col py-6'
                            @submit='completeSignup'
                            v-slot='{ values }'
                        >
                            <FormInput
                                autocomplete='new-password'
                                :label='$t("lbl-password")'
                                type='password'
                                name='password'
                                rules='required'
                                ref='password'
                                mode='aggressive'
                                edit
                            />
                            <div class='mt-6'>
                                <ul class='list-none pl-8 text-blue-400'>
                                    <li class='list-none' v-for='rule in passwordRules' :key='rule.label'>
                                        <span
                                            v-if='values.password && values.password.length > 0 && checkPasswordRule(rule.regex, values.password)'
                                            class='text-green-300'
                                        >
                                            <i class='fa fa-check mr-2'></i>{{ $t(rule.label) }}
                                        </span>
                                        <span v-else class='text-red-300'>
                                            <i class='fa fa-times mr-2'></i>{{ $t(rule.label) }}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <ButtonBar thinner no-margin>
                                <FriendlyButton
                                    label='btn-previous'
                                    symbol='arrow-left'
                                    square
                                    secondary
                                    :action='goBackToInformation'
                                    inverted-icon
                                />
                                <FriendlyButton
                                    label='btn-create-user'
                                    symbol='arrow-right'
                                    square
                                    type='submit'
                                    :disabled='!isPasswordStrong(values.password)'
                                    :loading='ongoingSignup'
                                />
                            </ButtonBar>
                        </Form>
                        <div class='mt-4'>
                            <span class='text-gray-400'>
                                {{ $t('terms-and-conditions-disclaimer-new') }} <a class='cursor-pointer' @click='openTermsPopup'>{{ $t('a-terms-and-conditions') }}</a>.
                            </span>
                        </div>
                    </WizardBox>
                </WizardScreen>

                <WizardScreen key='done' v-if='step === "done"' class='flex-col justify-start'>
                    <WizardBox animated class='mt-12'>
                        <Translated>
                            <template #en>
                                <div class='mb-12 font-light text-center text-6xl'>
                                    Great, you’re ready to go!
                                </div>
                                <div class='font-light text-center text-xl'>
                                    You’re just one click away from exploring MyCodabox for <span class='font-black'>{{ invitationEntityName }}</span>.
                                </div>
                            </template>
                            <template #nl>
                                <div class='mb-12 font-light text-center text-6xl'>
                                    Geweldig, je bent klaar om aan de slag te gaan!
                                </div>
                                <div class='font-light text-center text-xl'>
                                    Je bent nog maar één klik verwijderd van het verkennen van MyCodabox voor <span class='font-black'>{{ invitationEntityName }}</span>.
                                </div>
                            </template>
                            <template #fr>
                                <div class='mb-12 font-light text-center text-6xl'>
                                    Génial, tout est prêt !
                                </div>
                                <div class='font-light text-center text-xl'>
                                    Vous pouvez cliquer ci-dessous pour accèder <span class='font-black'>{{ invitationEntityName }}</span>.
                                </div>
                            </template>
                        </Translated>
                        <ButtonBar thinner>
                            <FriendlyButton
                                label='btn-invitation-back-home'
                                symbol='home'
                                square
                                :action='() => $router.push({ name: "login", query: { email: invitation.email } })'
                            />
                        </ButtonBar>
                    </WizardBox>
                </WizardScreen>
            </WizardTransitions>
            <Popup
                :show='logoutPopup'
                small
                :close='() => {}'
                not-closable
            >
                <template #header>
                    {{ $t('ttl-signup-logout-popup') }}
                </template>

                <div>
                    {{ $t('p-signup-logout', { email: $store.state.user.email }) }}
                </div>
                <div class='mb-6'>
                    {{ $t('p-signup-logout-p2') }}
                </div>

                <template #buttons>
                    <div class='flex'>
                        <FriendlyButton
                            label='btn-signup-logout-logout'
                            small
                            no-margin
                            id='email-pill-email-verification-do-resend'
                            :action='() => logout()'
                        />
                    </div>
                </template>
            </Popup>
        </div>
        <div v-else-if='!loading' class='flex flex-col h-full justify-center pb-24'>
            <WizardTransitions>
                <WizardScreen>
                    <WizardBox>
                        <div class='mt-12 font-light text-center text-6xl'>
                            <span class='font-black'>
                                <template v-if='isInvitationExpired'>
                                    {{ $t('invitation-wizard-expired-invitation-title') }}
                                </template>
                                <template v-else>
                                    {{ $t('invitation-wizard-invalid-invitation-title') }}
                                </template>
                            </span>
                        </div>
                        <div class='mt-12 text-center text-2xl'>
                            <template v-if='isInvitationExpired'>
                                {{ $t('invitation-wizard-expired-invitation-text') }}
                            </template>
                            <template v-else>
                                {{ $t('invitation-wizard-invalid-invitation-text') }}
                            </template>
                        </div>
                        <ButtonBar thinner v-if='!isInvitationExpired'>
                            <div
                                @mouseover='isHovering = true'
                                @mouseout='isHovering = false'
                            >
                                <FriendlyButton
                                    label='btn-invitation-back-home'
                                    symbol='home'
                                    square
                                    :action='() => $router.push("/")'
                                />
                            </div>
                        </ButtonBar>
                    </WizardBox>
                </WizardScreen>
            </WizardTransitions>
        </div>
    </div>
</template>

<script>
import { authFetch as fetch } from '../api/fetch.js';
import utils from '../utils.js';
import NavBar from '@/components/NavBar';
import FormInput from '@/components/FormInput.vue';
import BreadCrumbs from '../clientcomponents/BreadCrumbs.vue';
import WizardTransitions from '../clientcomponents/WizardTransitions.vue';
import WizardScreen from '../clientcomponents/WizardScreen.vue';
import WizardBox from '../clientcomponents/WizardBox.vue';
import ButtonBar from '../clientcomponents/ButtonBar.vue';
import FriendlyButton from '../clientcomponents/FriendlyButton.vue';
import validate from '@/validate';
import notify from '@/notify';
import { Auth } from '@aws-amplify/auth';
import Popup from '@/clientcomponents/Popup';
import loader from '@/loader.js';
import TermsConditionsPopUp from '@/user/TermsConditionsPopUp.vue';
import { PASSWORD_RULES } from '../constants/common';
import Translated from '@/components/Translated';
import { Form } from 'vee-validate';

export default {
    name: 'SignupView',
    components: {
        TermsConditionsPopUp,
        NavBar,
        FormInput,
        BreadCrumbs,
        WizardTransitions,
        WizardScreen,
        WizardBox,
        ButtonBar,
        FriendlyButton,
        Popup,
        Translated,
        // eslint-disable-next-line vue/no-reserved-component-names
        Form,
    },
    data () {
        return {
            loading: false,
            invitation: null,
            step: 'information',
            history: ['information'],
            user: {
                firstname: '',
                lastname: '',
            },
            passwordRules: PASSWORD_RULES,
            ongoingSignup: false,
            logoutPopup: false,
            showTermsPopup: false,
            isInvitationExpired: false,
        };
    },
    computed: {
        wizardSteps () {
            return [
                {
                    keys: ['information'],
                    label: 'lbl-user-signup-step-information',
                },
                {
                    keys: ['password'],
                    label: 'lbl-user-signup-step-password',
                    freeze: ['information'],
                },
                {
                    keys: ['done'],
                    label: 'lbl-user-signup-step-done',
                    freeze: ['information', 'password'],
                },
            ];
        },
        invitationEntityName () {
            return this.invitation.organization ?  this.invitation.organization.enterpriseName : this.invitation.reseller.name;
        },
    },
    async created () {
        const loadId = loader.start();
        this.loading = true;

        const invitationResponse = await this.getInvitation();

        // Only set local variables when request is valid and invitation is found
        // If the variables are not set, it will display the "invalid" information text
        if (invitationResponse.ok) {
            this.invitation = await invitationResponse.json();

            // if the user exists, it means that he should confirm and not signup
            if (this.invitation.user) {
                this.$router.push({ path: `/confirm-invitation/${this.$route.params.invitationToken}` });
            }

            this.isInvitationExpired = Date.now() > new Date(this.invitation.expiresAt);
        }

        try {
            const user = await Auth.currentAuthenticatedUser();
            if (user) {
                this.logoutPopup = true;
            }
        } catch (e) { }

        loader.stop(loadId);
        this.loading = false;
    },
    methods: {
        isPasswordStrong (password) {
            return password && validate.isPasswordStrong(password);
        },
        getInvitation () {
            return fetch(`/bff/api/invitation?token=${this.$route.params.invitationToken}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },
        async completeSignup (values) {
            this.ongoingSignup = true;
            if (this.user.firstname && this.user.lastname && this.isPasswordStrong(values.password)) {
                try {
                    const invitationToken = this.$route.params.invitationToken;
                    const response = await fetch(`/bff/api/signup?invitationToken=${invitationToken}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            firstname: this.user.firstname,
                            lastname: this.user.lastname,
                            password: values.password,
                        }),
                    });
                    if (response.status === 204) {
                        this.nextStep('done');
                    } else if (response.status === 400) {
                        const errorCode = (await response.json()).errorCode;

                        if (errorCode === 'invalidPassword') {
                            notify.error(this.$t('err-invalid-password'));
                            this.$refs.passwordForm.setErrors({
                                password: [this.$t('err-signup-invalid-password')],
                            });
                        } else if (errorCode === 'invitationExpired') {
                            this.isInvitationExpired = true;
                        } else {
                            notify.error(this.$t('err-unknown'));
                        }
                    } else {
                        notify.error(this.$t('err-unknown'));
                    }
                } catch (e) {
                    notify.error(this.$t('err-unknown'));
                }

                this.ongoingSignup = false;
            }
        },
        goToPassword (values) {
            this.user.firstname = values.firstname;
            this.user.lastname = values.lastname;
            this.nextStep('password');
        },
        goBackToInformation () {
            this.nextStep('information');
        },
        nextStep (step) {
            this.step = step;
            setTimeout(() => {
                utils.scrollTop();
            }, 150);
        },
        checkPasswordRule (rgx, password) {
            return validate.checkPasswordRule(rgx, password);
        },
        openTermsPopup (e) {
            e.stopPropagation();
            this.showTermsPopup = true;
        },
        closeTermsPopup () {
            this.showTermsPopup = false;
        },
        async logout () {
            await this.$store.dispatch('logout');
            this.logoutPopup = false;
        },
    },
};
</script>
