<script setup>
import CodaSignaturePopup from '@/components/CodaSignaturePopup.vue';
import Popup from '@/clientcomponents/Popup.vue';
import CodaSignaturePopupDone from '@/components/CodaSignaturePopupDone.vue';
import { computed, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';

const currentMandateIndex = ref(-1);
const currentMandate = ref(null);

const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    clientV2: {
        type: Object,
        required: true,
    },
    mandates: {
        type: Object,
        required: true,
    },
    isDirectCustomer: {
        type: Boolean,
        required: true,
    },
});
const emits = defineEmits(['close']);

const isTTMOnly = computed(() => {
    return currentMandate.value?.bankAccounts.filter(ba => ba.state !== 'archived').every(ba => ba.flowState === 'draft-active-bank-mandate');
});
const isSignHerePackageBeingCreated = computed(() => (currentMandate.value?.bank.isSignhereSupported || isTTMOnly.value) && !currentMandate.value?.signHerePackage?.packageSignUrl);
const pollerId = ref(null);

watch(() => props.show, (shown) => {
    currentMandateIndex.value = shown ? 0 : -1;
});

watch(() => currentMandateIndex.value, (index) => {
    clearInterval(pollerId.value);
    if (currentMandateIndex.value < 0 || currentMandateIndex.value >= props.mandates.length) {
        currentMandate.value = null;
        return;
    }

    currentMandate.value = props.mandates[currentMandateIndex.value];
    if (isSignHerePackageBeingCreated.value) {
        retryRefreshMandate();
    }
});

function retryRefreshMandate () {
    refreshMandate();
    pollerId.value = setInterval(() => {
        if (!isSignHerePackageBeingCreated.value) {
            clearInterval(pollerId.value);
        } else {
            refreshMandate();
        }
    }, 1000);
}

function closeSignaturePopup () {
    if (currentMandateIndex.value + 1 < props.mandates.length) {
        currentMandateIndex.value += 1;
    } else {
        currentMandateIndex.value = -1;
    }
}

function refreshMandate () {
    const { result, onResult } = useQuery(
        gql`query RefreshCodaMandate($clientId: String, $codaMandateId: String) {
            codaMandate(clientId:$clientId, codaMandateId:$codaMandateId) {
                id
                clientId
                fiduciaryId
                bankId
                bank {
                    id
                    name
                    slug
                    abbreviation
                    isSupported
                    isTwikeySupported
                    disableSendPdfMandate
                    isCaroSupported
                    isStopCodaSupported
                    regexSavings
                    isSignhereSupported
                }
                state
                reasonCode
                reasonNote
                stateDate
                bankAccounts{
                    id
                    type
                    bankId
                    state
                    stateDate
                    flowState
                    bankMandateState
                    iban
                }
                signHerePackage {
                    packageStatus
                    packageSignUrl
                    packageRejectionReason
                }
                twikeyUrl
                twikeyUrlCreatedAt
                hasTeletransmissionPdf
                reminders {
                    date
                    targetEmail
                    type
                }
            }
        }`,
        {
            clientId: props.clientV2.id,
            codaMandateId: currentMandate.value.id,
        },
    );
    onResult(() => {
        const res = result.value.codaMandate;
        if (currentMandate.value.id === res.id) {
            currentMandate.value = res;
        }
    });
}

</script>

<template>
    <Popup :show='show && currentMandateIndex >= 0' :close='closeSignaturePopup' small>
        <template #header>
            {{ $t('sign-coda.title') }}
        </template>
        <CodaSignaturePopup
            v-if='currentMandate'
            :mandate='currentMandate'
            :client-v2='clientV2'
            :close='closeSignaturePopup'
            :refresh-mandate='refreshMandate'
            next-button
        />
    </Popup>
    <CodaSignaturePopupDone
        :close='() => emits("close")'
        :show='show && currentMandateIndex < 0'
        :is-direct-customer='props.isDirectCustomer'
    />
</template>
