<script setup>
import ClientAddCoda from '@/components/ClientAddCoda.vue';
import FormCheckbox from '@/components/FormCheckbox';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import Loader from '@/loader';
import Popup from '@/clientcomponents/Popup.vue';
import { useStore } from 'vuex';
import utils from '@/utils';
import validate from '@/validate';
import { Form } from 'vee-validate';
import { datadogRum } from '@datadog/browser-rum';
import { gql } from '@apollo/client/core';
import { computed, ref } from 'vue';
import { useMutation } from '@vue/apollo-composable';

const emits = defineEmits(['close']);
const props = defineProps({
    showOrderCodaMandatePopup: {
        type: Boolean,
        required: true,
    },
    clientV2: {
        type: Object,
        required: true,
    },
    currentEnvironment: {
        type: Object,
        required: true,
    },
    isDirectCustomer: {
        type: Boolean,
        required: true,
    },
});

const orderCodaMandateForm = ref(null);
const bankAccountInputs = ref(null);
const saving = ref(false);
const store = useStore();
const iCanSign = ref(props.isDirectCustomer);
const nextActionLabel = computed(() => {
    // without feature flag, just the old button
    if (!store.state.flags.icansign) {
        return 'btn-save-mandate';
    }

    if (iCanSign.value) {
        return 'btn-next';
    } else {
        return 'btn-save-mandate';
    }
});
const nextActionSymbol = computed(() => {
  // without feature flag, just the old button
    if (!store.state.flags.icansign) {
        return 'save';
    }

    if (iCanSign.value) {
        return 'arrow-right';
    } else {
        return 'save';
    }
});

function logICanSign () {
    datadogRum.addAction('coda-order-i-can-sign-clicked', {
        clientId: props.clientV2.id,
        isDirectCustomer: props.isDirectCustomer,
        iCanSign: iCanSign.value,
    });
}

function isSubmitOrderCodaMandateDisabled (formValues) {
    const hasExistingBASelected = formValues.existingBankAccounts && formValues.existingBankAccounts.some(ba => !!ba);
    const hasNewBA = formValues.newBankAccounts && formValues.newBankAccounts.length > 0;
    return saving.value || (!hasNewBA && !hasExistingBASelected);
}

async function submitOrderCodaMandateForm (values) {
    Loader.start();
    saving.value = true;

    const bankAccountInputsValid = await bankAccountInputs.value.validateBankAccounts();

    if (!bankAccountInputsValid) {
        Loader.stop();
        saving.value = false;
        return;
    }

    // Build the list of bank accounts to order based on new BA inputs and existing BA checkboxes
    const bankAccountsToOrder = [];

    if (values.newBankAccounts && values.newBankAccounts.length > 0) {
        values.newBankAccounts.forEach(ba => {
            if (ba) {
                bankAccountsToOrder.push({
                    id: ba.replace(/\s+/g, ''),
                });
            }
        });
    }

    if (values.existingBankAccounts && values.existingBankAccounts.length > 0) {
        values.existingBankAccounts.forEach(ba => {
            if (ba) {
                bankAccountsToOrder.push({
                    id: ba.replace(/\s+/g, ''),
                });
            }
        });
    }

    const { mutate: orderCodaMandate } = useMutation(gql`
        mutation ($input: CodaOrderingInput!) {
            orderCodaMandate(input: $input) {
                data {
                    id
                    clientId
                    fiduciaryId
                    bankId
                    bank {
                        id
                        name
                        slug
                        abbreviation
                        isSupported
                        isTwikeySupported
                        disableSendPdfMandate
                        isCaroSupported
                        isStopCodaSupported
                        regexSavings
                        isSignhereSupported
                    }
                    state
                    reasonCode
                    reasonNote
                    stateDate
                    bankAccounts{
                        id
                        type
                        bankId
                        state
                        stateDate
                        flowState
                        bankMandateState
                        iban
                    }
                    signHerePackage {
                        packageStatus
                        packageSignUrl
                        packageRejectionReason
                    }
                    twikeyUrl
                    twikeyUrlCreatedAt
                    hasTeletransmissionPdf
                    reminders {
                        date
                        targetEmail
                        type
                    }
                },
                errors {
                    code,
                    detail,
                    source {
                        pointer
                    }
                }
            }
        }
    `);

    const res = await orderCodaMandate({
        input: {
            fiduciaryId: props.currentEnvironment.id,
            clientId: props.clientV2.id,
            bankAccounts: bankAccountsToOrder,
        },
    });

    const response = res.data.orderCodaMandate;

    Loader.stop();
    saving.value = false;

    if (response.errors) {
        try {
            await catchOrderCodaMandateErrors(response.errors);
        } catch (err) {
            await validate.notifyGQLValidationErrors(err);
        }
        return;
    }
    if (response.data) {
        emits('close', response.data, iCanSign.value && store.state.flags.icansign);
    }
}

function catchOrderCodaMandateErrors (errors) {
    for (let i = 0; i < errors.length; i++) {
        const error = errors[i];
        if (error && error.source && error.source.pointer.match(/^\/data\/bankAccounts\/\d+\/id$/) && bankAccountInputs.value) {
            const inputIndex = error.source.pointer.substring(19, 20);
            // only show the first error returned for each bank account (which has the highest priority)
            if (orderCodaMandateForm.value.errors[`newBankAccounts[${inputIndex}]`] === undefined) {
                orderCodaMandateForm.value.setErrors({
                    [`newBankAccounts[${inputIndex}]`]: 'err-' + error.code,
                });
            }
            delete errors[i];
        }
    }

    if (utils.count(errors)) {
        return Promise.reject(errors);
    }

    return Promise.resolve();
}

function closeOrderCodaMandatePopup () {
    // Reset signing value
    iCanSign.value = props.isDirectCustomer;
    emits('close', null);
}
</script>

<template>
    <Form
        ref='orderCodaMandateForm'
        @submit='submitOrderCodaMandateForm'
        v-slot='{ values }'
    >
        <Popup
            :show='showOrderCodaMandatePopup'
            id='orderCodaMandatePopup'
            :close='closeOrderCodaMandatePopup'
        >
            <template #header>
                {{ $t('ttl-order-coda-step-1') }}
            </template>

            <ClientAddCoda
                :client-v2='clientV2'
                ref='bankAccountInputs'
                :form-ref='orderCodaMandateForm'
            />

            <template #buttons>
                <template v-if='store.state.flags.icansign'>
                    <FormCheckbox
                        name='canSign'
                        id='can-sign-checkbox'
                        :label='$t("lbl-i-can-sign-mandates")'
                        :value='isDirectCustomer'
                        :model-value='iCanSign'
                        @update:model-value='(event) => (iCanSign = event)'
                        edit
                    />
                </template>
                <FriendlyButton
                    label='btn-cancel'
                    :action='closeOrderCodaMandatePopup'
                    square
                    extra-small
                    no-margin
                    secondary
                    class='mr-2'
                />
                <FriendlyButton
                    :label='nextActionLabel'
                    :disabled='isSubmitOrderCodaMandateDisabled(values)'
                    :symbol='nextActionSymbol'
                    type='submit'
                    square
                    extra-small
                    no-margin
                    @click='logICanSign'
                />
            </template>
        </Popup>
    </Form>
</template>
